import React from 'react';
import './feestmenu.scss';
import Container from '../../container/container';

class Feestmenu extends React.Component {
  render() {
    return (
      <>
        <div className={'block__feestmenu'}>
          <Container>
            <h2>Wat zit erin?</h2>
            <p>
              De inhoud van de feestboxen wordt bepaald door chef Alison die
              voortdurend op zoek gaat naar verrassende combinaties en intense
              smaken. Alles is dagvers bereid, je kan ze perfect nog 2 dagen
              bewaren in de koeling.
            </p>
            <p>
              De feestbox is een mix van koude hapjes en gerechtjes die je zelf
              nog even kort kan opwarmen.
            </p>
            <p>Elk gerecht is beschikbaar in vlees/veggie variant.</p>
            <h3>Apéro</h3>
            <div className="djar-card-wrapper">
              <div className="djar-card">
                <div className="img-wrapper">
                  <img src="https://cms.djar.fit/sites/default/files/2020-11/IMG_2677.jpg" />
                </div>
                <div className="txt-wrapper">
                  <p>
                    Dip van rode biet &amp; feta met grissini &amp;
                    groentenchips
                  </p>
                </div>
              </div>
              <div className="djar-card">
                <div className="img-wrapper">
                  <img src="https://cms.djar.fit/sites/default/files/2020-11/IMG_2684.jpg" />
                </div>
                <div className="txt-wrapper">
                  <p>
                    Flatbread pizza met hummus, halfgedroogde tomaten, feta,
                    spinazie, pesto
                  </p>
                </div>
              </div>
              <div className="djar-card">
                <div className="img-wrapper">
                  <img src="https://cms.djar.fit/sites/default/files/2020-11/IMG_2721.jpg" />
                </div>
                <div className="txt-wrapper">
                  <p>
                    Amuses in a DJAR:
                    <br />
                    <small>
                      Als je voor vis koos:
                      <br /> zalm, zalmtartaar, dillecrème, tartaar van
                      kabeljauw, curry, bloemkool
                    </small>
                  </p>
                </div>
              </div>
              <div className="djar-card">
                <div className="img-wrapper">
                  <img src="https://cms.djar.fit/sites/default/files/2020-11/IMG_2721.jpg" />
                </div>
                <div className="txt-wrapper">
                  <p>
                    Amuses in a DJAR (veggie-variant)
                    <br />
                    <small>
                      Als je voor veggie koos:
                      <br /> geitenkaas, paprika, tomatenconfijt, zoete
                      aardappel, quinoa, feta
                    </small>
                  </p>
                </div>
              </div>
              <div className="djar-card">
                <div className="img-wrapper">
                  <img src="https://cms.djar.fit/sites/default/files/2020-11/IMG_2711.jpg" />
                </div>
                <div className="txt-wrapper">
                  <p>
                    Fillo saucijzenbroodje met appel &amp; cider &amp;
                    veenbessencompote (warm)
                  </p>
                </div>
              </div>
            </div>
            <h3>Warme gerechtjes om te delen</h3>
            <div className="djar-card-wrapper">
              <div className="djar-card">
                <div className="img-wrapper">
                  <img src="https://cms.djar.fit/sites/default/files/2020-11/IMG_2694.jpg" />
                </div>
                <div className="txt-wrapper">
                  <p>
                    4x dumpling van rode paprika, pompoencrème, geroosterde
                    pastinaak, chioggia biet, geitenkaas crumble, cress
                  </p>
                </div>
              </div>
              <div className="djar-card">
                <div className="img-wrapper">
                  <img src="https://cms.djar.fit/sites/default/files/2020-11/IMG_2709.jpg" />
                </div>
                <div className="txt-wrapper">
                  <p>
                    2 x soepje van wortel en pastinaak met zure room, peterselie
                    en notencrunch
                  </p>
                </div>
              </div>
              <div className="djar-card">
                <div className="img-wrapper">
                  <img src="https://cms.djar.fit/sites/default/files/2020-11/IMG_2776.jpg" />
                </div>
                <div className="txt-wrapper">
                  <p>
                    2x risotto met geitenkaas, boschampignons, krokante
                    parmaham, notencrumble en spinazie
                  </p>
                </div>
              </div>
            </div>
            <h3>Afsluiter</h3>
            <div className="djar-card-wrapper">
              <div className="djar-card">
                <div className="img-wrapper">
                  <img src="https://cms.djar.fit/sites/default/files/2020-11/IMG_5395.jpg" />
                </div>
                <div className="txt-wrapper">
                  <p>
                    <strong>Zoete afsluiter</strong>: 2 stukjes brownie by
                    Julie's House
                  </p>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </>
    );
  }
}
export default Feestmenu;

import React from 'react';
import { Logo } from '../../blocks/logo-overview/logo-overview';
import './client-preview.scss';
import '../../blocks/logo-overview/logo-overview.scss';
import { Link } from 'gatsby';
import { parseDrupalURI } from '../../../helpers/helpers';

export default function ClientPreview({ clients, content, langCode }) {
  console.log(content);
  return (
    <div className="client-preview-wrapper">
      <div className="container">
        <div className="content">
          {content.title && <h2>{content.title}</h2>}
          {content.subtitle && (
            <div
              className="subtitle"
              dangerouslySetInnerHTML={{ __html: content.subtitle.value }}
            ></div>
          )}
        </div>
        <div className="logos-container">
          {clients.map(client => {
            return <Logo customer={client} key={client.id} />;
          })}
        </div>
        {content.link && (
          <Link
            to={parseDrupalURI(content.link.uri, langCode)}
            className={['btn big'].join(' ')}
          >
            {content.link.title}
          </Link>
        )}
      </div>
    </div>
  );
}

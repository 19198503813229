import React from 'react';

import './references.scss';
import Container from '../../container/container';
import { Link } from 'gatsby';
import Slider from 'react-slick';
import { parseDrupalURI } from '../../../helpers/helpers';

class References extends React.Component {
  constructor(props) {
    super(props);
    this.state = { browser: false };
  }

  componentDidMount() {
    this.setState({ browser: true });
  }

  render() {
    const settings = {
      arrows: true,
      autoplay: true,
      autoplaySpeed: 3000,
      dots: false,
      infinite: true,
      speed: 500,
      lazyLoad: 'progressive',
      slidesToShow: 5,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            autoplaySpeed: 2000,
            slidesToScroll: 1,
            infinite: true
          }
        }
      ]
    };

    const { title, items, children, colors, cta, align, langCode } = this.props;

    return (
      <div
        className={[
          'paragraph__references',
          align.toLowerCase(),
          colors ? colors.toLowerCase() : 'standaard'
        ].join(' ')}
      >
        <Container>
          {title && <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>}
          {children && (
            <div
              className="text"
              dangerouslySetInnerHTML={{ __html: children }}
            ></div>
          )}

          {items && this.state.browser && (
            <Slider {...settings}>
              {items.map((item, index) => {
                if (!item.field_logo || item.field_particuliere_klant) {
                  return null;
                }

                return (
                  <div key={index} className="reference-item">
                    <div className="reference-item__logo">
                      {item.field_website && item.field_website.uri ? (
                        <a
                          href={parseDrupalURI(
                            item.field_website.uri,
                            langCode
                          )}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            loading="lazy"
                            src={
                              item.field_logo.imageDerivatives.links
                                .icon_landscape.href
                            }
                            alt={item.title}
                          ></img>
                        </a>
                      ) : (
                        <img
                          loading="lazy"
                          src={
                            item.field_logo.imageDerivatives.links
                              .icon_landscape.href
                          }
                          alt={item.title}
                        ></img>
                      )}
                    </div>
                  </div>
                );
              })}
            </Slider>
          )}

          {cta && (
            <div className="cta">
              {cta.map((button, index) => {
                if (parseDrupalURI(button.uri).charAt(0) === '/') {
                  return (
                    <div className="cta__item" key={index}>
                      <Link
                        to={parseDrupalURI(button.uri, langCode)}
                        className={[
                          'btn big',
                          index % 2 === 0 ? '' : 'secondary'
                        ].join(' ')}
                      >
                        {button.title}
                      </Link>
                    </div>
                  );
                } else if (parseDrupalURI(button.uri).charAt(0) === '#') {
                  return (
                    <div className="cta__item" key={index}>
                      <a
                        href={parseDrupalURI(button.uri, langCode)}
                        className={[
                          'btn big',
                          index % 2 === 0 ? '' : 'secondary',
                          align.toLowerCase()
                        ].join(' ')}
                      >
                        {button.title}
                      </a>
                    </div>
                  );
                } else {
                  return (
                    <div className="cta__item" key={index}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={parseDrupalURI(button.uri, langCode)}
                        className={[
                          'btn big',
                          index % 2 === 0 ? '' : 'secondary',
                          align.toLowerCase()
                        ].join(' ')}
                      >
                        {button.title}
                      </a>
                    </div>
                  );
                }
              })}
            </div>
          )}
        </Container>
      </div>
    );
  }
}

export default References;

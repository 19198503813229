import React from 'react';
import './catalogus-item.scss';

export default class CatalogusItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      textShown: false
    };
  }

  toggleText(show) {
    this.setState({
      textShown: show
    });
  }

  componentDidMount() {
    const textHeight = this.textElement.clientHeight;
    this.setState({ textHeight });
  }

  componentDidUpdate() {
    const textHeight = this.textElement.clientHeight;
    if (this.state.textHeight !== textHeight) this.setState({ textHeight });
  }

  render() {
    const { item } = this.props;
    return (
      <div className="paragraph__catalogus__item-wrapper">
        <div
          className="paragraph__catalogus_item"
          onMouseEnter={() => this.toggleText(true)}
          onMouseLeave={() => this.toggleText(false)}
        >
          <picture>
            <source
              srcset={
                item.relationships.field_media.field_media_image
                  .imageDerivatives.links.djar_card_webp.href
              }
              type="image/webp"
            />
            <source
              srcset={
                item.relationships.field_media.field_media_image
                  .imageDerivatives.links.djar_card.href
              }
              type="image/jpeg"
            />
            <img
              src={
                item.relationships.field_media.field_media_image
                  .imageDerivatives.links.djar_card.href
              }
              style={
                this.state.textShown
                  ? {
                      top: this.state.textHeight
                        ? -this.state.textHeight
                        : '-3rem'
                    }
                  : {}
              }
              alt={item.field_title}
              loading="lazy"
            />
          </picture>
          <h3
            ref={textElement => {
              this.textElement = textElement;
            }}
            dangerouslySetInnerHTML={{ __html: item.field_title }}
          ></h3>
        </div>
      </div>
    );
  }
}

import React from 'react';

import './guidance.scss';
import Container from '../../container/container';
import { parseDrupalURI } from '../../../helpers/helpers';

class Guidance extends React.PureComponent {
  render() {
    const { title, text, box, items, colors, align, langCode } = this.props;

    return (
      <div
        className={[
          'paragraph__guidance',
          align.toLowerCase(),
          colors ? colors.toLowerCase() : 'standaard',
          box ? 'box' : 'no-box'
        ].join(' ')}
      >
        <Container>
          {title && <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>}
          {text && (
            <div
              className="text"
              dangerouslySetInnerHTML={{
                __html: text
              }}
            ></div>
          )}
          {items && (
            <div className="paragraph__guidance_items">
              {items.map((item, index) => {
                if (item.field_link) {
                  return (
                    <a
                      href={parseDrupalURI(item.field_link.uri, langCode)}
                      key={index}
                      className="paragraph__guidance_item clean"
                    >
                      {item.relationships.field_media && (
                        <div className="paragraph__guidance_item__image">
                          <picture>
                            <source
                              srcset={
                                item.relationships.field_media.field_media_image
                                  .imageDerivatives.links.story_paragraph_webp
                                  .href
                              }
                              type="image/webp"
                            />
                            <source
                              srcset={
                                item.relationships.field_media.field_media_image
                                  .imageDerivatives.links.story_paragraph.href
                              }
                              type="image/jpeg"
                            />
                            <img
                              src={
                                item.relationships.field_media.field_media_image
                                  .imageDerivatives.links.story_paragraph.href
                              }
                              alt={
                                item.relationships.field_media.field_media_image
                                  .alt
                              }
                              loading="lazy"
                            />
                          </picture>
                        </div>
                      )}
                      <div className="paragraph__guidance_item__content">
                        <h3
                          dangerouslySetInnerHTML={{
                            __html: item.field_title
                          }}
                        ></h3>
                        {item.field_text && (
                          <div
                            className="text"
                            dangerouslySetInnerHTML={{
                              __html: item.field_text
                            }}
                          ></div>
                        )}
                        {item.field_button_text && (
                          <a
                            href={parseDrupalURI(item.field_link.uri, langCode)}
                            className="btn"
                          >
                            {item.field_button_text}
                          </a>
                        )}
                      </div>
                    </a>
                  );
                } else {
                  return (
                    <div key={index} className="paragraph__guidance_item clean">
                      {item.relationships.field_media && (
                        <div className="paragraph__guidance_item__image">
                          <picture>
                            <source
                              srcset={
                                item.relationships.field_media.field_media_image
                                  .imageDerivatives.links.story_paragraph_webp
                                  .href
                              }
                              type="image/webp"
                            />
                            <source
                              srcset={
                                item.relationships.field_media.field_media_image
                                  .imageDerivatives.links.story_paragraph.href
                              }
                              type="image/jpeg"
                            />
                            <img
                              src={
                                item.relationships.field_media.field_media_image
                                  .imageDerivatives.links.story_paragraph.href
                              }
                              alt={
                                item.relationships.field_media.field_media_image
                                  .alt
                              }
                              loading="lazy"
                            />
                          </picture>
                        </div>
                      )}
                      <div className="paragraph__guidance_item__content">
                        <h3
                          dangerouslySetInnerHTML={{
                            __html: item.field_title
                          }}
                        ></h3>
                        <div
                          className="text"
                          dangerouslySetInnerHTML={{
                            __html: item.field_text
                          }}
                        ></div>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          )}
        </Container>
      </div>
    );
  }
}

export default Guidance;

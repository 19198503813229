import React from 'react';

import './newsletter.scss';
import Container from '../../container/container';

const Newsletter = ({ title, colors }) => {
  return (
    <div
      className={[
        'paragraph__newsletter',
        colors ? colors.toLowerCase() : 'standaard'
      ].join(' ')}
    >
      <Container>
        <div className="text">
          <h2
            dangerouslySetInnerHTML={{
              __html: title
            }}
          ></h2>
        </div>
        <div className="form">
          <div id="mc_embed_signup">
            <form
              action="https://fit.us15.list-manage.com/subscribe/post?u=0e3b7a00f04a0efd1fb3bec53&amp;id=9e795b4f0c"
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              className="validate"
              target="_blank"
              noValidate
            >
              <input
                type="email"
                name="EMAIL"
                className="required email"
                placeholder="E-mailadres"
                id="mce-EMAIL"
              />
              <button
                type="submit"
                name="subscribe"
                id="mc-embedded-subscribe"
                className="button"
              >
                Inschrijven
              </button>
            </form>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Newsletter;

import React from 'react';

import './numeration.scss';
import Container from '../../container/container';
import Fade from 'react-reveal/Fade';
import ParallaxImage from '../../../parallax-image/parallax-image';
import { Parallax } from 'react-scroll-parallax';
import shape from './../../../images/shape.svg';

const Numeration = ({ title, children, image, imageWebp, alt, number }) => {
  return (
    <div className={['paragraph__numeration'].join(' ')}>
      <Container>
        <div className="content">
          <p class="number">#{number}</p>
          {title && <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>}
          {children && (
            <div
              className="text"
              dangerouslySetInnerHTML={{ __html: children }}
            ></div>
          )}
        </div>
        <div className="paragraph__numeration__image">
          <Fade>
            <picture>
              <source srcset={imageWebp} type="image/webp" />
              <source srcset={image} type="image/jpeg" />
              <img src={image} alt={alt} loading="lazy" />
            </picture>
          </Fade>
          <Parallax className="shape" y={[20, -20]}>
            <Fade ssrReveal>
              <ParallaxImage alt="" src={shape} />
            </Fade>
          </Parallax>
        </div>
      </Container>
    </div>
  );
};

export default Numeration;

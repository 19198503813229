import React, { useState } from 'react';
import { parseDrupalURI } from '../../../helpers/helpers';
import { navigate } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function NavigationItem({ item, langCode }) {
  const [hover, setHover] = useState(false);
  const { image, mainLink, links, imageWebp } = item;

  const handleMouseEnter = e => {
    if (e.target.classList.contains('link')) {
      setHover(false);
    } else {
      setHover(true);
    }
  };
  const handleMouseLeave = e => {
    setHover(false);
  };
  const redirect = e => {
    if (e.currentTarget === e.target) {
      navigate(parseDrupalURI(mainLink.uri, langCode));
    }
  };
  return (
    <div
      className={[`navigation-item`, hover ? 'hover' : ''].join(' ')}
      onMouseOver={handleMouseEnter}
      onFocus={handleMouseEnter}
      onBlur={handleMouseLeave}
      onMouseLeave={handleMouseLeave}
    >
      <div className="image-wrapper">
        <a href={parseDrupalURI(mainLink.uri, langCode)}>
          <picture>
            <source srcset={imageWebp} type="image/webp" />
            <source srcset={image} type="image/jpeg" />
            <img src={image} alt="" />
          </picture>
        </a>
      </div>
      <div onClick={redirect} className="content">
        <h2>
          <a href={parseDrupalURI(mainLink.uri, langCode)}>{mainLink.title}</a>
        </h2>
      </div>
    </div>
  );
}

import React, { useEffect, useRef, useState } from 'react';
import Container from '../../container/container';
import './logo-overview.scss';
import Modal from 'react-modal';

Modal.setAppElement('#___gatsby');

export default function LogoOverview({ customers }) {
  const [totalRows, setTotalRows] = useState();
  const [totalColumns, setTotalColumns] = useState();
  const filteredCustomers = [];
  customers.filter(customer => {
    if (customer.field_logo && !customer.field_particuliere_klant) {
      filteredCustomers.push(customer);
    }
  });
  useEffect(() => {
    const totalCustomers = filteredCustomers.length;
    const handleWindowResize = () => {
      if (window.innerWidth > 991) {
        setTotalColumns(4);
        const rows = Math.ceil(totalCustomers / totalColumns);
        setTotalRows(rows);
      } else if (window.innerWidth > 767) {
        setTotalColumns(3);
        const rows = Math.ceil(totalCustomers / totalColumns);
        setTotalRows(rows);
      } else if (window.innerWidth > 599) {
        setTotalColumns(2);
        const rows = Math.ceil(totalCustomers / totalColumns);
        setTotalRows(rows);
      } else {
        setTotalColumns(1);
        const rows = Math.ceil(totalCustomers / totalColumns);
        setTotalRows(rows);
      }
    };
    handleWindowResize();

    const resizeListener = window.addEventListener(
      'resize',
      handleWindowResize
    );

    return () => window.removeEventListener('resize', resizeListener);
  }, [totalColumns]);

  return (
    <Container>
      <div className="logos-container">
        {filteredCustomers.map((customer, index) => {
          const currentRow = Math.ceil((index + 1) / totalColumns);
          let position = 'bottom';
          if (totalRows - currentRow <= 1) {
            position = 'top';
          }
          return (
            <Logo key={index} customer={customer} quotePosition={position} />
          );
        })}
      </div>
    </Container>
  );
}

export function Logo({ customer, quotePosition = 'top' }) {
  const [showQuote, setShowQuote] = useState(false);
  const [showFullscreenQuote, setShowFullscreenQuote] = useState(false);

  const handleMouseEnter = () => {
    setShowQuote(true);
  };
  const handleMouseLeave = () => {
    setShowQuote(false);
  };

  const handleClick = () => {
    if (window.innerWidth > 1024) return;
    setShowFullscreenQuote(true);
    document.querySelector('html').classList.add('ReactModal__Body--open');
  };

  const handleModalClose = () => {
    setShowFullscreenQuote(false);
    document.querySelector('html').classList.remove('ReactModal__Body--open');
  };
  return (
    <>
      <div
        className="logo-container"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
      >
        <img
          className="logo"
          loading="lazy"
          src={customer.field_logo.imageDerivatives.links.icon_landscape.href}
          alt={customer.title}
        ></img>
        {customer.field_quote && showQuote && (
          <Quote customer={customer} position={quotePosition} />
        )}
      </div>
      {customer.field_quote && (
        <Modal
          isOpen={showFullscreenQuote}
          onRequestClose={handleModalClose}
          className="Modal"
          overlayClassName="Overlay"
        >
          <FullScreenQuote
            customer={customer}
            hide={() => setShowFullscreenQuote(false)}
          />
          <span className="close" onClick={handleModalClose}>
            X
          </span>
        </Modal>
      )}
    </>
  );
}

function Quote({ customer, position }) {
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current) {
      ref.current.classList.add('open');
    }

    return () => {
      if (ref.current) {
        ref.current.classList.remove('open');
      }
    };
  }, []);
  return (
    <div className={`quote-wrapper ${position}`} ref={ref}>
      <div
        className="quote"
        dangerouslySetInnerHTML={{
          __html: customer.field_quote
        }}
      ></div>
      <p className="customer-info">
        <strong>{customer.title}</strong>
        {customer.field_location && <span>, {customer.field_location}</span>}
      </p>
    </div>
  );
}

function FullScreenQuote({ customer, hide }) {
  return (
    <div className={`quote-wrapper fullscreen`}>
      <div
        className="quote"
        dangerouslySetInnerHTML={{
          __html: customer.field_quote
        }}
      ></div>
      <p className="customer-info">
        <strong>{customer.title}</strong>
        {customer.field_location && <span>, {customer.field_location}</span>}
      </p>
    </div>
  );
}

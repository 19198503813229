import React from 'react';
import Reviews from '../components/blocks/reviews/reviews';
import Feestmenu from '../components/blocks/feestmenu/feestmenu';
import LogoOverview from '../components/blocks/logo-overview/logo-overview';
import Giftcards from '../components/blocks/giftcards/giftcards';

const renderBlock = (block, data, index) => {
  switch (block.name) {
    case 'Klantenreviews':
      return (
        <Reviews key={index} customers={data.allNodeCustomer.nodes}></Reviews>
      );
    case 'Feestbox-menu':
      return <Feestmenu key={index} />;
    case "Overzicht logo's":
      return (
        <LogoOverview key={index} customers={data.allNodeCustomer.nodes} />
      );
    case 'Cadeaubonnen':
      return <Giftcards key={index} />;
    default:
      return <></>;
  }
};

export default renderBlock;

import React from 'react';
import { useState } from 'react';
import DjarDetail from '../djar-overview/djar-detail/djar-detail';
import './menu-of-the-month.scss';

export default function MenuOfTheMonth({ djars, title }) {
  const [selectedDjar, setSelectedDjar] = useState(null);

  function selectDjar(djar) {
    document.body.style.top = `-${window.scrollY}px`;
    document.body.style.position = 'fixed';
    setSelectedDjar(djar);
  }

  function closeModal() {
    const scrollY = document.body.style.top;
    document.body.style.position = '';
    document.body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
    setSelectedDjar(null);
  }
  return (
    <div className="menu-of-the-month-wrapper">
      <div className="container">
        {title && <h2 style={{ textAlign: 'center' }}>{title}</h2>}
        <div className="featured-djars-container">
          {djars.map(djar => {
            const {
              relationships: { field_djar: field_djar }
            } = djar;
            return (
              <div className="featured-djar">
                <div className="content">
                  <picture>
                    <source
                      srcset={
                        field_djar.relationships.field_media.field_media_image
                          .imageDerivatives.links.djar_card_webp.href
                      }
                      type="image/webp"
                    />
                    <source
                      srcset={
                        field_djar.relationships.field_media.field_media_image
                          .imageDerivatives.links.djar_card.href
                      }
                      type="image/jpeg"
                    />
                    <img
                      src={
                        field_djar.relationships.field_media.field_media_image
                          .imageDerivatives.links.djar_card.href
                      }
                      loading="lazy"
                      alt=""
                    />
                  </picture>
                  {field_djar.title && <h3>{field_djar.title}</h3>}
                </div>
                <button onClick={() => selectDjar(field_djar)}>
                  Meer over deze DJAR
                </button>
              </div>
            );
          })}
        </div>
      </div>
      {selectedDjar && <DjarDetail djar={selectedDjar} close={closeModal} />}
    </div>
  );
}

import React from 'react';

import './form-story.scss';

class FormStory extends React.Component {
  constructor(props) {
    super(props);
    const { form_id } = props;
    this.state = {
      src: '//mautic.djar.fit/form/generate.js?id=' + form_id
    };
  }

  componentDidMount() {
    const { form_id } = this.props;
    let formScript = document.createElement('script');
    formScript.type = 'text/javascript';
    formScript.src = this.state.src;
    document
      .querySelector(`.paragraph__form-story .form_${form_id} .content`)
      .appendChild(formScript);
  }

  render() {
    const { id, title, text, colors, style, form_id } = this.props;
    return (
      <div
        id={'p' + id}
        className={[
          'paragraph__form-story',
          colors ? colors.toLowerCase() : 'standaard',
          style === 'Rechts' ? 'rechts' : 'links'
        ].join(' ')}
      >
        <div className="paragraph__form-story__text">
          <div className="content">
            {title && <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>}
            {text && (
              <div
                className="text"
                dangerouslySetInnerHTML={{ __html: text }}
              ></div>
            )}
          </div>
        </div>
        <div
          className={['paragraph__form-story__form', 'form_' + form_id].join(
            ' '
          )}
        >
          <div className="content"></div>
        </div>
      </div>
    );
  }
}

export default FormStory;

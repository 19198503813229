import React from 'react';

import './djar-overview.scss';
import Container from '../../container/container';
import DjarDetail from './djar-detail/djar-detail';
import Fade from 'react-reveal/Fade';

export default class DjarOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = { selectedDjar: null };
  }

  selectDjar(djar) {
    document.body.style.top = `-${window.scrollY}px`;
    document.body.style.position = 'fixed';
    this.setState({ selectedDjar: djar });
  }

  closeModal() {
    const scrollY = document.body.style.top;
    document.body.style.position = '';
    document.body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
    this.setState({ selectedDjar: null });
  }

  render() {
    const { title, children, items, align } = this.props;

    return (
      <div
        className={['paragraph__djar_overview', align.toLowerCase()].join(' ')}
      >
        <Container>
          {title && <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>}
          {children && (
            <div
              className="text"
              dangerouslySetInnerHTML={{ __html: children }}
            ></div>
          )}
          <Fade>
            <div className="djar__items">
              {items.map((item, key) => (
                <div
                  className="djar__item"
                  onClick={e => {
                    this.selectDjar(item);
                  }}
                  key={key}
                >
                  <picture>
                    <source
                      srcset={
                        item.relationships.field_media.field_media_image
                          .imageDerivatives.links.djar_card_webp.href
                      }
                      type="image/webp"
                    />
                    <source
                      srcset={
                        item.relationships.field_media.field_media_image
                          .imageDerivatives.links.djar_card.href
                      }
                      type="image/jpeg"
                    />
                    <img
                      src={
                        item.relationships.field_media.field_media_image
                          .imageDerivatives.links.djar_card.href
                      }
                      alt=""
                      loading="lazy"
                    />
                  </picture>
                  <h3>{item.title}</h3>
                </div>
              ))}
            </div>
          </Fade>
        </Container>
        {this.state.selectedDjar && (
          <DjarDetail
            djar={this.state.selectedDjar}
            close={() => {
              this.closeModal();
            }}
          ></DjarDetail>
        )}
      </div>
    );
  }
}

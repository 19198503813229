import React from 'react';
import Slider from 'react-slick';
import './reviews.scss';
import { parseDrupalURI } from '../../../helpers/helpers';

class Reviews extends React.Component {
  constructor(props) {
    super(props);
    this.state = { browser: false };
  }

  componentDidMount() {
    this.setState({ browser: true });
  }

  render() {
    const settings = {
      arrows: true,
      autoplay: true,
      autoplaySpeed: 10000,
      dots: false,
      infinite: true,
      speed: 500,
      centerMode: true,
      centerPadding: '20%',
      lazyLoad: 'progressive',
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            centerMode: true,
            centerPadding: '50px',
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true
          }
        },
        {
          breakpoint: 768,
          settings: {
            centerMode: true,
            centerPadding: '0',
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true
          }
        }
      ]
    };

    const { customers, langCode } = this.props;
    return (
      <div className={'block__reviews'}>
        {customers && this.state.browser && (
          <Slider {...settings}>
            {customers
              .filter(customer => customer.field_quote)
              .map((customer, index) => (
                <div key={index} className="review-item">
                  <div className="logo">
                    {customer.field_logo &&
                      (customer.field_website && customer.field_website.uri ? (
                        <a
                          href={parseDrupalURI(
                            customer.field_website.uri,
                            langCode
                          )}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            loading="lazy"
                            src={
                              customer.field_logo.imageDerivatives.links
                                .icon_landscape.href
                            }
                            alt={customer.title}
                          ></img>
                        </a>
                      ) : (
                        <img
                          loading="lazy"
                          src={
                            customer.field_logo.imageDerivatives.links
                              .icon_landscape.href
                          }
                          alt={customer.title}
                        ></img>
                      ))}
                  </div>
                  <div
                    className="quote"
                    dangerouslySetInnerHTML={{
                      __html: customer.field_quote
                    }}
                  ></div>
                  <hr />
                  <p>
                    <strong>{customer.title}</strong>
                    {customer.field_location && (
                      <span>
                        <br />
                        {customer.field_location}
                      </span>
                    )}
                  </p>
                </div>
              ))}
          </Slider>
        )}
      </div>
    );
  }
}

export default Reviews;

import React from 'react';

import './story.scss';
import { Link } from 'gatsby';
import { parseDrupalURI } from '../../../helpers/helpers';
import ReactHtmlParser from 'react-html-parser';

const Story = ({
  title,
  children,
  image,
  imageWebp,
  alt,
  style,
  cta,
  langCode
}) => {
  let body = new ReactHtmlParser(children, {
    transform: function transform(node) {
      if (node.type === 'tag' && node.name === 'img') {
        let className = node.attribs['class'];
        let alt = node.attribs['alt'];
        let src = node.attribs['src'];
        return (
          <img
            className={['lazyload', className].join(' ')}
            data-src={'https://cms.djar.fit' + src}
            alt={alt}
          />
        );
      }

      if (node.type === 'script' && node.name === 'script') {
        let src = node.attribs['src'];
      }
      return undefined;
    }
  });
  return (
    <div
      className={[
        'paragraph__story',
        style === 'Rechts' ? 'rechts' : 'links'
      ].join(' ')}
    >
      <div className="paragraph__story-text">
        <div className="content">
          {title && <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>}
          {children && <div className="text">{body}</div>}
          {cta && cta.length > 0 && (
            <div className="cta">
              {cta.map((button, index) => {
                if (parseDrupalURI(button.uri).charAt(0) === '/') {
                  return (
                    <div className="cta__item" key={index}>
                      <Link
                        to={parseDrupalURI(button.uri, langCode)}
                        className={[
                          'btn big',
                          index % 2 === 0 ? '' : 'secondary'
                        ].join(' ')}
                      >
                        {button.title}
                      </Link>
                    </div>
                  );
                } else if (parseDrupalURI(button.uri).charAt(0) === '#') {
                  return (
                    <div className="cta__item" key={index}>
                      <a
                        href={parseDrupalURI(button.uri, langCode)}
                        className={[
                          'btn big',
                          index % 2 === 0 ? '' : 'secondary'
                        ].join(' ')}
                      >
                        {button.title}
                      </a>
                    </div>
                  );
                } else {
                  return (
                    <div className="cta__item" key={index}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={parseDrupalURI(button.uri, langCode)}
                        className={[
                          'btn big',
                          index % 2 === 0 ? '' : 'secondary'
                        ].join(' ')}
                      >
                        {button.title}
                      </a>
                    </div>
                  );
                }
              })}
            </div>
          )}
        </div>
      </div>
      <div className="paragraph__story-image">
        <picture>
          <source srcset={imageWebp} type="image/webp" />
          <source srcset={image} type="image/jpeg" />
          <img loading="lazy" src={image} alt={alt} />
        </picture>
      </div>
    </div>
  );
};

export default Story;

import React from 'react';
import Container from '../../container/container';
import NavigationItem from './navigation-item';

import './navigation.scss';

export default function Navigation({ items, colorScheme, langCode }) {
  return (
    <div className={['navigation-wrapper', colorScheme].join(' ')}>
      <Container>
        <div className="navigation-container">
          {items.map(item => (
            <NavigationItem item={item} langCode={langCode} />
          ))}
        </div>
      </Container>
    </div>
  );
}

import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.scss';

import './catalogus.scss';
import Container from '../../container/container';
import CatalogusItem from '../../catalogus-item/catalogus-item';

class Catalogus extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { browser: false };
  }

  componentDidMount() {
    this.setState({
      browser: true
    });
  }

  render() {
    const { title, items, colors, align } = this.props;

    const settings = {
      arrows: true,
      autoplay: true,
      autoplaySpeed: 7000,
      dots: true,
      infinite: items.length > 4,
      speed: 500,
      lazyLoad: 'progressive',
      slidesToShow: 4,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1200,
          infinite: items.length > 3,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 767,
          infinite: items.length > 2,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          infinite: items.length > 1,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        }
      ]
    };

    return (
      <div
        className={[
          'paragraph__catalogus',
          align.toLowerCase(),
          colors ? colors.toLowerCase() : 'standaard'
        ].join(' ')}
      >
        <Container>
          {title && <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>}
          {items && (
            <div className="paragraph__catalogus_items">
              {this.state.browser && (
                <Slider {...settings}>
                  {items.map((item, index) => (
                    <CatalogusItem key={index} item={item}></CatalogusItem>
                  ))}
                </Slider>
              )}
            </div>
          )}
        </Container>
      </div>
    );
  }
}

export default Catalogus;

import React from 'react';

import './ebook-download.scss';
import Container from '../../container/container';

class EbookDownload extends React.Component {
  componentDidMount() {
    const { form_id } = this.props;
    let formScript = document.createElement('script');
    formScript.type = 'text/javascript';
    formScript.src = '//mautic.djar.fit/form/generate.js?id=' + form_id;
    document
      .querySelector(`.paragraph__ebook-download .form_${form_id} .content`)
      .appendChild(formScript);
  }

  render() {
    const { id, title, text, colors, image, imageWebp, form_id } = this.props;
    return (
      <div
        id={'p' + id}
        className={[
          'paragraph__ebook-download',
          colors ? colors.toLowerCase() : 'standaard'
        ].join(' ')}
      >
        <Container>
          <div
            className={[
              'paragraph__ebook-download__form',
              'form_' + form_id
            ].join(' ')}
          >
            <div className="content">
              {title && <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>}
              {text && (
                <div
                  className="text"
                  dangerouslySetInnerHTML={{ __html: text }}
                ></div>
              )}
            </div>
          </div>
          <div className="paragraph__ebook-download__image">
            <div className="content">
              <picture>
                <source srcset={imageWebp} type="image/webp" />
                <source srcset={image} type="image/jpeg" />
                <img src={image} alt={''} loading="lazy" />
              </picture>
            </div>
          </div>
        </Container>
        <svg
          class="bg"
          xmlns="http://www.w3.org/2000/svg"
          width="1500"
          height="309.318"
          viewBox="0 0 1500 310"
        >
          <path
            fill="#c0e2c8"
            d="M0 39.841s114.641 209.29 386.844 209.29 450.545-231.342 732.343-246.4S1500 39.841 1500 39.841v269.477H0z"
          />
        </svg>
      </div>
    );
  }
}

export default EbookDownload;

import React from 'react';

import './hero.scss';
import Container from '../../container/container';
import circle from './../../../images/circle.svg';

import { Parallax } from 'react-scroll-parallax';
import Fade from 'react-reveal/Fade';
import ParallaxImage from '../../../parallax-image/parallax-image';

const Hero = ({ title, image, imageWebp, alt }) => {
  return (
    <div className={['paragraph__hero'].join(' ')}>
      <div className="paragraph__hero__image">
        <picture>
          <source srcset={imageWebp} type="image/webp" />
          <source srcset={image} type="image/jpeg" />
          <img src={image} alt="" loading="lazy" />
        </picture>
      </div>
      <Container>
        <Parallax className="circle" y={[15, -15]}>
          <Fade ssrReveal>
            <ParallaxImage src={circle} alt="" />
          </Fade>
        </Parallax>
        <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>
      </Container>
    </div>
  );
};

export default Hero;

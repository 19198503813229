import React from 'react';

import './djar-detail.scss';
import Container from '../../../container/container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DjarDetail = ({ djar, close }) => {
  return (
    <div className="djar_detail">
      <div className="modal">
        <div className="go-back" onClick={close}>
          <p>
            <FontAwesomeIcon icon={'caret-left'} />
            &nbsp;&nbsp;&nbsp;Terug naar het overzicht
          </p>
        </div>
        <Container>
          <div className="content">
            <div className="header">
              <h4>{djar.title}</h4>
              {djar.field_hashtag && (
                <p className="hashtag"># {djar.field_hashtag}</p>
              )}
            </div>
            <div className="main">
              <h5>Ingrediënten:</h5>
              <div className="ingredients">
                {djar.relationships.field_ingredients &&
                  djar.relationships.field_ingredients.map(
                    (ingredient, key) => (
                      <div key={key} className="ingredient">
                        {ingredient.field_photo ? (
                          <picture>
                            <source
                              srcset={
                                ingredient.field_photo.imageDerivatives.links
                                  .thumb_webp.href
                              }
                              type="image/webp"
                            />
                            <source
                              srcset={
                                ingredient.field_photo.imageDerivatives.links
                                  .thumb.href
                              }
                              type="image/jpeg"
                            />
                            <img
                              src={
                                ingredient.field_photo.imageDerivatives.links
                                  .thumb.href
                              }
                              alt={ingredient.title}
                              loading="lazy"
                            />
                          </picture>
                        ) : (
                          <img
                            loading="lazy"
                            src="data:image/png;base64 ,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mOsvjf/PwAGzQL5TCbqLgAAAABJRU5ErkJggg=="
                            alt=""
                          />
                        )}
                        <p>{ingredient.title}</p>
                      </div>
                    )
                  )}
              </div>
              {djar.relationships.field_variants && (
                <div className="variants">
                  {djar.relationships.field_variants.map((variant, index) => (
                    <div key={index} className="variant">
                      <h5>{variant.field_title}</h5>
                      <div className="variant__ingredients">
                        {variant.relationships.field_ingredients &&
                          variant.relationships.field_ingredients.map(
                            (ingredient, key) => (
                              <div key={key} className="variant__ingredient">
                                {ingredient.field_photo ? (
                                  <picture>
                                    <source
                                      srcset={
                                        ingredient.field_photo.imageDerivatives
                                          .links.thumb_webp.href
                                      }
                                      type="image/webp"
                                    />
                                    <source
                                      srcset={
                                        ingredient.field_photo.imageDerivatives
                                          .links.thumb.href
                                      }
                                      type="image/jpeg"
                                    />
                                    <img
                                      src={
                                        ingredient.field_photo.imageDerivatives
                                          .links.thumb.href
                                      }
                                      alt={ingredient.title}
                                      loading="lazy"
                                    />
                                  </picture>
                                ) : (
                                  <img
                                    loading="lazy"
                                    src="data:image/png;base64 ,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mOsvjf/PwAGzQL5TCbqLgAAAABJRU5ErkJggg=="
                                    alt=""
                                  />
                                )}
                                <p>{ingredient.title}</p>
                              </div>
                            )
                          )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="image">
            {djar.relationships.field_media.field_media_image && (
              <picture>
                <source
                  srcset={
                    djar.relationships.field_media.field_media_image
                      .imageDerivatives.links.djar_card_webp.href
                  }
                  type="image/webp"
                />
                <source
                  srcset={
                    djar.relationships.field_media.field_media_image
                      .imageDerivatives.links.djar_card.href
                  }
                  type="image/jpeg"
                />
                <img
                  src={
                    djar.relationships.field_media.field_media_image
                      .imageDerivatives.links.djar_card.href
                  }
                  alt=""
                  loading="lazy"
                />
              </picture>
            )}
            {djar.field_dyk && (
              <div className="dyk">
                <h5>Weetje</h5>
                <p>
                  <strong>Wist je dat </strong>
                  {djar.field_dyk}
                </p>
              </div>
            )}
          </div>
        </Container>
      </div>
    </div>
  );
};

export default DjarDetail;

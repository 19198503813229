import React from 'react';

import './faq.scss';
import Container from '../../container/container';
import FAQItem from './faq-item/faq-item';
import Fade from 'react-reveal/Fade';

const FAQ = ({ title, text, items, align, colors }) => {
  return (
    <div
      className={[
        'paragraph__faq',
        align.toLowerCase(),
        colors ? colors.toLowerCase() : 'standaard'
      ].join(' ')}
    >
      <Container>
        {title && <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>}
        {text && (
          <div
            className="text"
            dangerouslySetInnerHTML={{ __html: text }}
          ></div>
        )}
        <Fade>
          <div className="faq__items">
            {items.map((item, index) => (
              <FAQItem
                key={index}
                question={item.field_title}
                answer={item.field_text}
              ></FAQItem>
            ))}
          </div>
        </Fade>
      </Container>
    </div>
  );
};

export default FAQ;

import React, { useEffect, useRef } from 'react';
import ReactHtmlParser from 'react-html-parser';

import './text.scss';
import Container from '../../container/container';

const Text = ({ children, colors, align }) => {
  const wrappingDiv = useRef(null);
  let src;
  let body = new ReactHtmlParser(children, {
    transform: function transform(node) {
      if (node.type === 'tag' && node.name === 'img') {
        let alt = node.attribs['alt'];
        let src = node.attribs['src'];
        return (
          <img src={'https://cms.djar.fit' + src} loading="lazy" alt={alt} />
        );
      }

      if (node.type === 'script' && node.name === 'script') {
        src = node.attribs['src'];
      }
      return undefined;
    }
  });

  useEffect(() => {
    if (src) {
      const script = document.createElement('script');
      script.async = true;
      script.src = src;
      wrappingDiv.current.appendChild(script);
    }
  });

  return (
    <div
      ref={wrappingDiv}
      className={[
        'paragraph__text',
        align.toLowerCase(),
        colors ? colors.toLowerCase() : 'standaard'
      ].join(' ')}
    >
      <Container>{children && <div className="text">{body}</div>}</Container>
    </div>
  );
};

export default Text;

import React from 'react';

import './focus.scss';
import Container from '../../container/container';
import { Link } from 'gatsby';
import { parseDrupalURI } from '../../../helpers/helpers';

const Focus = ({ id, title, children, colors, cta, align, langCode }) => {
  return (
    <div
      id={'p' + id}
      className={[
        'paragraph__focus',
        align.toLowerCase(),
        colors ? colors.toLowerCase() : 'standaard'
      ].join(' ')}
    >
      <Container>
        {title && <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>}
        {children && (
          <div
            className="text"
            dangerouslySetInnerHTML={{ __html: children }}
          ></div>
        )}
        {cta && cta.length > 0 && (
          <div className="cta">
            {cta.map((button, index) => {
              if (
                parseDrupalURI(button.uri).charAt(0) === '/' ||
                parseDrupalURI(button.uri).charAt(0) === '#'
              ) {
                return (
                  <div className="cta__item" key={index}>
                    <Link
                      to={parseDrupalURI(button.uri, langCode)}
                      className={[
                        'btn big',
                        index % 2 === 0
                          ? ''
                          : colors === 'Groen'
                          ? 'secondary dark'
                          : 'secondary'
                      ].join(' ')}
                    >
                      {button.title}
                    </Link>
                  </div>
                );
              } else {
                return (
                  <div className="cta__item" key={index}>
                    <a
                      href={parseDrupalURI(button.uri, langCode)}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={[
                        'btn big',
                        index % 2 === 0
                          ? ''
                          : colors === 'Groen'
                          ? 'secondary dark'
                          : 'secondary',
                        align.toLowerCase()
                      ].join(' ')}
                    >
                      {button.title}
                    </a>
                  </div>
                );
              }
            })}
          </div>
        )}
      </Container>
    </div>
  );
};

export default Focus;

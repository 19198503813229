import React from 'react';
import { withController } from 'react-scroll-parallax';
import { Component } from 'react';

class ParallaxImage extends Component {
  handleLoad = () => {
    this.props.parallaxController.update();
  };

  render() {
    return (
      <img src={this.props.src} alt={this.props.alt} onLoad={this.handleLoad} />
    );
  }
}

export default withController(ParallaxImage);

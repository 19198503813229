import React from 'react';

import './fancy-header.scss';
import { Link } from 'gatsby';
import Container from '../../container/container';
import Fade from 'react-reveal/Fade';
import { Parallax } from 'react-scroll-parallax';
import { parseDrupalURI } from '../../../helpers/helpers';

const FancyHeader = ({ title, children, cta, image, langCode, imageField }) => {
  const { story_large, story_large_webp } = imageField.imageDerivatives.links;
  return (
    <div className={'paragraph__fancy-header'}>
      <div className="shape-container">
        <Parallax className="shape" y={[5, -5]}>
          <Fade ssrReveal top>
            <div className="shape-item"></div>
          </Fade>
        </Parallax>
      </div>

      <div className="paragraph__fancy-header__image-container">
        <div className="paragraph__fancy-header__image">
          <Fade ssrReveal right>
            <picture>
              <source srcset={story_large_webp.href} type="image/webp" />
              <source srcset={story_large.href} type="image/jpeg" />
              <img src={story_large.href} alt="" />
            </picture>
          </Fade>
        </div>
      </div>

      <Container>
        <Fade ssrReveal left>
          <h1 dangerouslySetInnerHTML={{ __html: title }}></h1>
          {children && (
            <div
              className="text"
              dangerouslySetInnerHTML={{ __html: children }}
            ></div>
          )}
          {cta && (
            <div className="cta">
              {cta.map((button, index) => {
                if (
                  parseDrupalURI(button.uri).charAt(0) === '/' ||
                  parseDrupalURI(button.uri).charAt(0) === '#'
                ) {
                  return (
                    <div className="cta__item" key={index}>
                      <Link
                        to={parseDrupalURI(button.uri, langCode)}
                        className={[
                          'btn big',
                          index % 2 === 0 ? '' : 'secondary'
                        ].join(' ')}
                      >
                        {button.title}
                      </Link>
                    </div>
                  );
                } else {
                  return (
                    <div className="cta__item" key={index}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={parseDrupalURI(button.uri, langCode)}
                        className={[
                          'btn big',
                          index % 2 === 0 ? '' : 'secondary'
                        ].join(' ')}
                      >
                        {button.title}
                      </a>
                    </div>
                  );
                }
              })}
            </div>
          )}
        </Fade>
      </Container>
    </div>
  );
};

export default FancyHeader;

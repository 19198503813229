import React from 'react';
import './giftcards.scss';
import giftcardImage from '../../../images/DJAR-cadeaubon.jpeg';
import Fade from 'react-reveal/Fade';
import Container from '../../container/container';
export default function Giftcards() {
  return (
    <Fade>
      <div className="giftcards-wrapper grijs">
        <Container>
          <div className="giftcards-info">
            <img src={giftcardImage} alt="A giftcard" loading="lazy" />
            <div className="content">
              <h1>Cadeaubon</h1>
              <p>
                <b>Deel jouw liefde voor goed eten</b>
              </p>
              <p>Een smaakvol cadeau geven was nog nooit zo makkelijk! </p>
              <p>Deze cadeaubon is 1 jaar geldig.</p>

              <a
                href="https://bestel.djar.fit/cadeaubon"
                target="_blank"
                className="btn big"
              >
                Geef een DJAR cadeau
              </a>
            </div>
          </div>
        </Container>
      </div>
    </Fade>
  );
}

import React from 'react';

import './featured-djar.scss';
import Container from '../../container/container';
import DjarDetail from '../djar-overview/djar-detail/djar-detail';

import shape from './../../../images/shape-2.svg';
import warmStamp from './../../../images/heatmeupicon.svg';

import ParallaxImage from '../../../parallax-image/parallax-image';
import { Parallax } from 'react-scroll-parallax';
import Fade from 'react-reveal/Fade';

export default class FeaturedDjar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { selectedDjar: null };
  }

  selectDjar(djar) {
    document.body.style.top = `-${window.scrollY}px`;
    document.body.style.position = 'fixed';
    this.setState({ selectedDjar: djar });
  }

  closeModal() {
    const scrollY = document.body.style.top;
    document.body.style.position = '';
    document.body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
    this.setState({ selectedDjar: null });
  }

  render() {
    const { title, djar } = this.props;

    if (!djar || !title) return null;

    return (
      <>
        <div className={['paragraph__featured_djar'].join(' ')}>
          <Container>
            <div className="image">
              {title && <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>}
              {djar?.relationships.field_media.field_media_image && (
                <>
                  <div className="image-wrapper">
                    {djar.field_warm_gerecht && (
                      <div className="warm-stamp">
                        <img src={warmStamp} alt="stamp" />
                      </div>
                    )}
                    <picture>
                      <source
                        srcset={
                          djar.relationships.field_media.field_media_image
                            .imageDerivatives.links.djar_card_webp.href
                        }
                        type="image/webp"
                      />
                      <source
                        srcset={
                          djar.relationships.field_media.field_media_image
                            .imageDerivatives.links.djar_card.href
                        }
                        type="image/jpeg"
                      />
                      <img
                        src={
                          djar.relationships.field_media.field_media_image
                            .imageDerivatives.links.djar_card.href
                        }
                        loading="lazy"
                        alt=""
                      />
                    </picture>
                  </div>
                  <Parallax className="shape" y={[-15, 5]}>
                    <Fade ssrReveal bottom>
                      <ParallaxImage alt="" src={shape} />
                    </Fade>
                  </Parallax>
                </>
              )}
            </div>
            <div className="content">
              <div className="banner">
                <h3
                  dangerouslySetInnerHTML={{
                    __html: this.replaceDjar(djar.title)
                  }}
                ></h3>
                <button
                  onClick={e => {
                    this.selectDjar(djar);
                  }}
                >
                  Meer over deze Djar
                </button>
              </div>
            </div>
          </Container>
        </div>
        {this.state.selectedDjar && (
          <DjarDetail
            djar={this.state.selectedDjar}
            close={() => {
              this.closeModal();
            }}
          ></DjarDetail>
        )}
      </>
    );
  }

  replaceDjar(text) {
    return text;
  }
}

import React from 'react';

import './faq-item.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Fade from 'react-reveal/Fade';

class FAQItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  render() {
    const { question, answer } = this.props;
    return (
      <div className="faq__item">
        {this.state.open ? (
          <FontAwesomeIcon icon={['fa', 'chevron-up']}></FontAwesomeIcon>
        ) : (
          <FontAwesomeIcon icon={['fa', 'chevron-down']}></FontAwesomeIcon>
        )}
        <h3
          onClick={() => this.toggle()}
          dangerouslySetInnerHTML={{ __html: question }}
        ></h3>
        {this.state.open && (
          <Fade>
            <div
              className="faq__answer"
              dangerouslySetInnerHTML={{ __html: answer }}
            ></div>
          </Fade>
        )}
      </div>
    );
  }

  toggle() {
    this.setState({ open: !this.state.open });
  }
}

export default FAQItem;

import React from 'react';

import './form.scss';
import Container from '../../container/container';

class Form extends React.Component {
  componentDidMount() {
    const { form_id } = this.props;
    let formScript = document.createElement('script');
    formScript.type = 'text/javascript';
    formScript.src = '//mautic.djar.fit/form/generate.js?id=' + form_id;
    document
      .querySelector(`.paragraph__form .form_${form_id} .content`)
      .appendChild(formScript);
  }

  render() {
    const { id, title, text, colors, form_id } = this.props;
    return (
      <div
        id={'p' + id}
        className={[
          'paragraph__form',
          colors ? colors.toLowerCase() : 'standaard'
        ].join(' ')}
      >
        <Container>
          <div className="paragraph__form__text">
            <div className="content">
              {title && <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>}
              {text && (
                <div
                  className="text"
                  dangerouslySetInnerHTML={{ __html: text }}
                ></div>
              )}
            </div>
          </div>
          <div
            className={['paragraph__form__form', 'form_' + form_id].join(' ')}
          >
            <div className="content"></div>
          </div>
        </Container>
      </div>
    );
  }
}

export default Form;

import React from 'react';
import PageHeader from '../components/paragraphs/page-header/page-header';
import USP from '../components/paragraphs/usp/usp';
import Focus from '../components/paragraphs/focus/focus';
import Text from '../components/paragraphs/text/text';
import References from '../components/paragraphs/references/references';
import Story from '../components/paragraphs/story/story';
import Newsletter from '../components/paragraphs/newsletter/newsletter.js';
import renderBlock from './render-block';
import FAQ from '../components/paragraphs/faq/faq';
import EbookDownload from '../components/paragraphs/ebook-download/ebook-download';
import FormStory from '../components/paragraphs/form-story/form-story';
import FancyHeader from '../components/paragraphs/fancy-header/fancy-header';
import Guidance from '../components/paragraphs/guidance/guidance';
import Fade from 'react-reveal/Fade';
import TextBlocks from '../components/paragraphs/text-blocks/text-blocks';
import DjarOverview from '../components/paragraphs/djar-overview/djar-overview';
import Catalogus from '../components/paragraphs/catalogus/catalogus';
import FeaturedDjar from '../components/paragraphs/featured-djar/featured-djar';
import BackgroundImage from '../components/paragraphs/background-image/background-image';
import Hero from '../components/paragraphs/hero/hero';
import Numeration from '../components/paragraphs/numeration/numeration';
import Form from '../components/paragraphs/form/form';
import Navigation from '../components/paragraphs/navigation/navigation';
import MenuOfTheMonth from '../components/paragraphs/menu-of-the-month/menu-of-the-month';
import ClientPreview from '../components/paragraphs/client-preview/client-preview';

const renderParagraph = (paragraph, index, data, mobile, langCode = 'nl') => {
  if (!paragraph.internal) {
    return <></>;
  }
  switch (paragraph.internal.type) {
    case 'paragraph__page_header':
      return (
        <PageHeader
          key={index}
          title={paragraph.field_title}
          breadcrumbs={paragraph.field_breadcrumbs}
          cta={paragraph.field_cta}
          colors={paragraph.relationships.field_color_scheme.name}
          align={paragraph.relationships.field_alignment.name}
          langCode={langCode}
        >
          {paragraph.field_text && paragraph.field_text}
        </PageHeader>
      );
    case 'paragraph__fancy_header':
      return (
        <FancyHeader
          key={index}
          title={paragraph.field_title}
          cta={paragraph.field_cta}
          image={
            'https://cms.djar.fit' +
            paragraph.relationships.field_story_image.uri.url
          }
          imageField={paragraph.field_story_image}
          langCode={langCode}
        >
          {paragraph.field_text && paragraph.field_text}
        </FancyHeader>
      );
    case 'paragraph__hero':
      return (
        <Hero
          key={index}
          title={paragraph.field_title}
          image={
            paragraph.relationships.field_media.field_media_image
              .imageDerivatives.links.hero.href
          }
          imageWebp={
            paragraph.relationships.field_media.field_media_image
              .imageDerivatives.links.hero_webp.href
          }
          langCode={langCode}
        ></Hero>
      );
    case 'paragraph__background_image':
      return (
        <BackgroundImage
          key={index}
          title={paragraph.field_title}
          cta={paragraph.field_cta}
          align={paragraph.relationships.field_alignment.name}
          image={
            paragraph.relationships.field_media.field_media_image
              .imageDerivatives.links.story_paragraph.href
          }
          imageWebp={
            paragraph.relationships.field_media.field_media_image
              .imageDerivatives.links.story_paragraph_webp.href
          }
          langCode={langCode}
        >
          {paragraph.field_text && paragraph.field_text}
        </BackgroundImage>
      );
    case 'paragraph__usp':
      return (
        <Fade key={index}>
          <USP
            key={index}
            title={paragraph.field_title}
            items={paragraph.relationships.field_usp_items}
            colors={paragraph.relationships.field_color_scheme.name}
            align={paragraph.relationships.field_alignment.name}
            langCode={langCode}
          ></USP>
        </Fade>
      );
    case 'paragraph__guidance':
      return (
        <Fade key={index}>
          <Guidance
            box={paragraph.field_box}
            title={paragraph.field_title}
            text={paragraph.field_text}
            items={paragraph.relationships.field_guidance_items}
            colors={paragraph.relationships.field_color_scheme.name}
            align={paragraph.relationships.field_alignment.name}
            langCode={langCode}
          ></Guidance>
        </Fade>
      );
    case 'paragraph__text_blocks':
      return (
        <TextBlocks
          key={index}
          title={paragraph.field_title}
          text={paragraph.field_text}
          items={paragraph.relationships.field_text_blocks}
          colors={paragraph.relationships.field_color_scheme.name}
          align={paragraph.relationships.field_alignment.name}
          langCode={langCode}
        ></TextBlocks>
      );
    case 'paragraph__story':
      return (
        <Fade key={index}>
          <Story
            title={paragraph.field_title}
            cta={paragraph.field_cta}
            alt={paragraph.relationships.field_media.field_media_image.alt}
            style={paragraph.relationships.field_story_style.name}
            image={
              paragraph.relationships.field_media.field_media_image
                .imageDerivatives.links.story_paragraph.href
            }
            imageWebp={
              paragraph.relationships.field_media.field_media_image
                .imageDerivatives.links.story_paragraph_webp.href
            }
            mobile={mobile}
            langCode={langCode}
          >
            {paragraph.field_text && paragraph.field_text}
          </Story>
        </Fade>
      );
    case 'paragraph__numeration':
      return (
        <Numeration
          key={index}
          title={paragraph.field_title}
          alt={paragraph.relationships.field_media.field_media_image.alt}
          image={
            paragraph.relationships.field_media.field_media_image
              .imageDerivatives.links.hero.href
          }
          imageWebp={
            paragraph.relationships.field_media.field_media_image
              .imageDerivatives.links.hero_webp.href
          }
          number={paragraph.field_number}
          langCode={langCode}
        >
          {paragraph.field_text && paragraph.field_text}
        </Numeration>
      );
    case 'paragraph__form_story':
      return (
        <Fade key={index}>
          <FormStory
            id={paragraph.drupal_internal__id}
            title={paragraph.field_title}
            text={paragraph.field_text}
            colors={paragraph.relationships.field_color_scheme.name}
            style={paragraph.relationships.field_type.name}
            form_id={paragraph.field_form_id}
            langCode={langCode}
          ></FormStory>
        </Fade>
      );
    case 'paragraph__form':
      return (
        <Fade key={index}>
          <Form
            id={paragraph.drupal_internal__id}
            title={paragraph.field_title}
            text={paragraph.field_text}
            colors={paragraph.relationships.field_color_scheme.name}
            form_id={paragraph.field_form_id}
            langCode={langCode}
          ></Form>
        </Fade>
      );
    case 'paragraph__ebook_download':
      return (
        <Fade key={index}>
          <EbookDownload
            id={paragraph.drupal_internal__id}
            title={paragraph.field_title}
            text={paragraph.field_text}
            colors={paragraph.relationships.field_color_scheme.name}
            image={
              paragraph.relationships.field_media.field_media_image
                .imageDerivatives.links.story_large.href
            }
            imageWebp={
              paragraph.relationships.field_media.field_media_image
                .imageDerivatives.links.story_large_webp.href
            }
            form_id={paragraph.field_form_id}
          ></EbookDownload>
        </Fade>
      );
    case 'paragraph__focus':
      return (
        <Fade key={index}>
          <Focus
            id={paragraph.drupal_internal__id}
            title={paragraph.field_title}
            cta={paragraph.field_cta}
            colors={paragraph.relationships.field_color_scheme.name}
            align={paragraph.relationships.field_alignment.name}
            langCode={langCode}
          >
            {paragraph.field_text && paragraph.field_text}
          </Focus>
        </Fade>
      );
    case 'paragraph__catalogus':
      return (
        <Catalogus
          key={index}
          title={paragraph.field_title}
          colors={paragraph.relationships.field_color_scheme.name}
          align={paragraph.relationships.field_alignment.name}
          items={paragraph.relationships.field_items}
          langCode={langCode}
        >
          {paragraph.field_text && paragraph.field_text}
        </Catalogus>
      );
    case 'paragraph__featured_djar':
      return (
        <FeaturedDjar
          title={paragraph.field_title}
          text={paragraph.field_text && paragraph.field_text}
          djar={paragraph.relationships.field_djar}
          langCode={langCode}
        ></FeaturedDjar>
      );
    case 'paragraph__menu_van_de_maand':
      return (
        <MenuOfTheMonth
          title={paragraph.field_titel}
          langCode={langCode}
          djars={paragraph.relationships.field_featured_djar}
        ></MenuOfTheMonth>
      );
    case 'paragraph__klanten_preview':
      return (
        <ClientPreview
          content={{
            title: paragraph.field_titel,
            subtitle: paragraph.field_subtitle,
            link: paragraph.field_link
          }}
          langCode={langCode}
          clients={paragraph.relationships.field_client}
        />
      );
    case 'paragraph__djar_overview':
      return (
        <DjarOverview
          title={paragraph.field_title}
          align={paragraph.relationships.field_alignment.name}
          items={paragraph.relationships.field_djars}
          langCode={langCode}
        >
          {paragraph.field_text && paragraph.field_text}
        </DjarOverview>
      );
    case 'paragraph__text':
      return (
        <Text
          key={index}
          colors={paragraph.relationships.field_color_scheme.name}
          align={paragraph.relationships.field_alignment.name}
          langCode={langCode}
        >
          {paragraph.field_text && paragraph.field_text}
        </Text>
      );
    case 'paragraph__references':
      return (
        <Fade key={index}>
          <References
            title={paragraph.field_title}
            items={data.allNodeCustomer.nodes}
            cta={paragraph.field_cta}
            colors={paragraph.relationships.field_color_scheme.name}
            align={paragraph.relationships.field_alignment.name}
            langCode={langCode}
          >
            {paragraph.field_text && paragraph.field_text}
          </References>
        </Fade>
      );
    case 'paragraph__newsletter':
      return (
        <Fade key={index}>
          <Newsletter
            title={paragraph.field_title}
            colors={paragraph.relationships.field_color_scheme.name}
            langCode={langCode}
          ></Newsletter>
        </Fade>
      );
    case 'paragraph__block':
      return renderBlock(paragraph.relationships.field_type, data, index);
    case 'paragraph__faq':
      return (
        <FAQ
          key={index}
          title={paragraph.field_title}
          text={paragraph.field_text}
          items={paragraph.relationships.field_faq_items}
          colors={paragraph.relationships.field_color_scheme.name}
          align={paragraph.relationships.field_alignment.name}
          langCode={langCode}
        ></FAQ>
      );
    case 'paragraph__navigation':
      const items = [
        {
          image:
            paragraph.relationships.field_image_1.field_media_image
              .imageDerivatives.links.story_paragraph.href,
          imageWebp:
            paragraph.relationships.field_image_1.field_media_image
              .imageDerivatives.links.story_paragraph_webp.href,
          mainLink: paragraph.field_hoofdlink_1,
          links: paragraph.field_links_1
        },
        {
          image:
            paragraph.relationships.field_image_2.field_media_image
              .imageDerivatives.links.story_paragraph.href,
          imageWebp:
            paragraph.relationships.field_image_2.field_media_image
              .imageDerivatives.links.story_paragraph_webp.href,
          mainLink: paragraph.field_hoofdlink_2,
          links: paragraph.field_links_2
        }
      ];
      if (paragraph.relationships.field_image_3) {
        items.push({
          image:
            paragraph.relationships.field_image_3?.field_media_image
              .imageDerivatives.links.story_paragraph.href,
          imageWebp:
            paragraph.relationships.field_image_3?.field_media_image
              .imageDerivatives.links.story_paragraph_webp.href,
          mainLink: paragraph.field_hoofdlink_3
        });
      }
      return (
        <Navigation
          key={index}
          items={items}
          colorScheme={paragraph.relationships.field_color_scheme.name.toLowerCase()}
          langCode={langCode}
        ></Navigation>
      );
    default:
      return <></>;
  }
};

export default renderParagraph;

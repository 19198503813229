import React from 'react';

import './background-image.scss';
import { Link } from 'gatsby';
import Container from '../../container/container';
import { Parallax } from 'react-scroll-parallax';
import ParallaxImage from '../../../parallax-image/parallax-image';
import { parseDrupalURI } from '../../../helpers/helpers';

const BackgroundImage = ({ title, children, image, alt, cta, align, langCode }) => {
  return (
    <div
      className={[
        'paragraph__background-image',
        align ? align.toLowerCase() : ''
      ].join(' ')}
    >
      <div
        className="paragraph__background-image__image"
        style={{ backgroundImage: `url(${image})` }}
      >
        <Parallax
          y={[-20, 20]}
          styleOuter={{
            overflow: 'hidden',
            position: 'relative',
            top: '50%',
            transform: 'translateY(-50%)'
          }}
          styleInner={{ overflow: 'visible' }}
        >
          <ParallaxImage src={image} alt={alt} />
        </Parallax>
      </div>
      <Container>
        <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>
        {children && (
          <div
            className="text"
            dangerouslySetInnerHTML={{ __html: children }}
          ></div>
        )}
        {cta && cta.length > 0 && (
          <div className="cta">
            {cta.map((button, index) => {
              if (
                parseDrupalURI(button.uri).charAt(0) === '/' ||
                parseDrupalURI(button.uri).charAt(0) === '#'
              ) {
                return (
                  <div className="cta__item" key={index}>
                    <Link
                      to={parseDrupalURI(button.uri, langCode)}
                      className={[
                        'btn big',
                        index % 2 === 0 ? '' : 'secondary'
                      ].join(' ')}
                    >
                      {button.title}
                    </Link>
                  </div>
                );
              } else {
                return (
                  <div className="cta__item" key={index}>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={parseDrupalURI(button.uri, langCode)}
                      className={[
                        'btn big',
                        index % 2 === 0 ? '' : 'secondary'
                      ].join(' ')}
                    >
                      {button.title}
                    </a>
                  </div>
                );
              }
            })}
          </div>
        )}
      </Container>
    </div>
  );
};

export default BackgroundImage;

import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.scss';

import './usp.scss';
import Container from '../../container/container';

class USP extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { browser: false };
  }

  componentDidMount() {
    this.setState({
      browser: true
    });
  }

  render() {
    const { title, items, colors, align } = this.props;

    const settings = {
      arrows: false,
      autoplay: true,
      autoplaySpeed: 7000,
      dots: true,
      infinite: true,
      speed: 500,
      lazyLoad: 'progressive',
      slidesToShow: items.length > 2 ? 3 : 2,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        }
      ]
    };

    return (
      <div
        className={[
          'paragraph__usp',
          align.toLowerCase(),
          colors ? colors.toLowerCase() : 'standaard'
        ].join(' ')}
      >
        <Container>
          {title && <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>}
          {items && (
            <div className="paragraph__usp_items">
              <div
                className={`usp-${items.length <= 2 ? 'flow' : 'container'}`}
              >
                {this.state.browser && (
                  <Slider {...settings}>
                    {items.map(item => (
                      <div key={item.drupal_id} className="paragraph__usp_item">
                        {item.field_icon && (
                          <div className="paragraph__usp_item__icon">
                            <img
                              loading="lazy"
                              src={
                                item.field_icon.imageDerivatives.links.icon.href
                              }
                              alt={item.field_icon.alt}
                            ></img>
                          </div>
                        )}
                        <h3
                          dangerouslySetInnerHTML={{
                            __html: item.field_title
                          }}
                        ></h3>
                        <div
                          className="text"
                          dangerouslySetInnerHTML={{
                            __html: item.field_text
                          }}
                        ></div>
                      </div>
                    ))}
                  </Slider>
                )}
              </div>
            </div>
          )}
        </Container>
      </div>
    );
  }
}

export default USP;

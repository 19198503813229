import React from 'react';

import './text-blocks.scss';
import Container from '../../container/container';

class TextBlocks extends React.PureComponent {
  render() {
    const { title, text, items, colors, align } = this.props;

    return (
      <div
        className={[
          'paragraph__text_blocks',
          align.toLowerCase(),
          colors ? colors.toLowerCase() : 'standaard'
        ].join(' ')}
      >
        <Container>
          <div className="paragraph__text_blocks_grid">
            {title && <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>}
            {text && (
              <div
                className="text"
                dangerouslySetInnerHTML={{
                  __html: text
                }}
              ></div>
            )}
            {items && (
              <>
                {items.map((item, index) => {
                  return (
                    <>
                      <div key={index} className="paragraph__text_block">
                        {item.field_title && (
                          <h3
                            dangerouslySetInnerHTML={{
                              __html: item.field_title
                            }}
                          ></h3>
                        )}
                        <div className="text">
                          <div className="wrapper">
                            <Jar index={index % 6} />
                            <div
                              className="content"
                              dangerouslySetInnerHTML={{
                                __html: item.field_text
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </>
            )}
          </div>
        </Container>
      </div>
    );
  }
}

export default TextBlocks;

function Jar({ index }) {
  const positions = [
    'top-left',
    'middle-right',
    'middle-left',
    'bottom-right',
    '',
    ''
  ];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38.569"
      height="54.523"
      viewBox="0 0 38.569 54.523"
      stroke="#284b9e"
      className={positions[index]}
    >
      <g
        id="Group_551"
        data-name="Group 551"
        transform="translate(-188.158 -138.619)"
      >
        <path
          id="Path_247"
          data-name="Path 247"
          d="M220.264,163.5s-.091.37,1.579,1.563a9.141,9.141,0,0,1,3.641,7.282l.137,26.8s.522,4.994-1.5,6.945c-1.925,1.856-6.907,3.389-16.609,3.433s-14.573-1.427-16.516-3.263c-2.043-1.93-1.7-6.928-1.7-6.928l-.137-26.8a9.14,9.14,0,0,1,3.566-7.319c1.657-1.21,1.563-1.579,1.563-1.579"
          transform="translate(0 -17.379)"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <path
          id="Path_248"
          data-name="Path 248"
          d="M230.428,141.123c0-.873-6.747-1.545-15.06-1.5s-15.048.784-15.044,1.657l.021,4.127c0,.974,6.748,1.729,15.061,1.687s15.048-.867,15.043-1.841l-.021-4.127c0,.872-6.731,1.614-15.044,1.657s-15.055-.63-15.06-1.5"
          transform="translate(-8.126)"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <path
          id="Path_249"
          data-name="Path 249"
          d="M270.778,153.167s-3.322,1.761-15.043,1.821"
          transform="translate(-48.451 -9.86)"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1"
        />
      </g>
    </svg>
  );
}
